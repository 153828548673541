import React from "react";
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class PrivacyPolicy extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Privacy Policy - Niwot Inn &amp; Spa" />
        <div className="content-page">
          <div className="container">
            <h1>Privacy Policy</h1>
          </div>

        </div>
      </Layout>
    );
  };
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
